import Vue from 'vue'
import App from './App.vue'
import router from './router'

import axios from 'axios'
import moment from 'moment'
import {io} from 'socket.io-client'

import Buefy from 'buefy'
import VueCookies from 'vue-cookies'
import Transitions from 'vue2-transitions'
import CodeInput from "vue-verification-code-input"
import Avatar from 'vue-avatar'
import NavBar from './components/NavBar.vue'
import SideBar from './components/SideBar.vue'


import './scss/main.scss'

Vue.config.productionTip = true;

Vue.prototype.$http = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
});
Vue.prototype.$moment = moment
moment.locale('es')
Vue.prototype.$socket = io(process.env.VUE_APP_BASE_SOCKET,
    {
        autoConnect: false,
    })

Vue.component('code-input', CodeInput);
Vue.component('nav-bar', NavBar);
Vue.component('side-bar', SideBar);
Vue.component('avatar', Avatar);

Vue.use(Transitions);
Vue.use(VueCookies);
Vue.use(Buefy, {
    defaultIconPack: 'fas'
});
// Vue.config.errorHandler = function (err, vm, info) {
//     rg4js('send', {
//         error: err,
//         customData: [{info: info}]
//     });
// };

new Vue({
    router,
    render: h => h(App),

    data: {
        auth: {
            token: false,
            type: 'bearer',
            expiration: 0,
        },

        user: {},
        inbox: 0,

        chat: {
            display: false,
            loading: true,
            more: true,
            content: []
        },
        adminsConnected: [],

        notifications: {
            content: [],
            ready: false,
            modal: false,
            show: null,
            next: null
        },

        profile: {
            thurnail: null,
        },

        transactions: null,
        firstLogin: false
    },

    watch: {

        auth: {

            deep: true,
            handler: function () {

                if (this.auth.token !== false) {

                    this.loadUser();
                    this.getNotifications();

                }

            }

        },

        user: {
            deep: true,
            handler: function () {

                if (this.auth.token !== false) {

                    this.loadTransactions();
                    this.loadThurnail();
                    //this.connectSocket();

                }

                if (Object.prototype.hasOwnProperty.call(this.user, 'email') && Object.prototype.hasOwnProperty.call(this.user, 'name')) {
                    if (this.toComplete) {

                        if (this.$route.name !== 'ToCompletePre') {

                            this.$router.push({
                                name: 'ToCompletePre'
                            })

                        }

                    }
                }

            }
        },

    },

    computed: {
        isAuthenticate() {
            return !(this.auth.token === false || this.auth.token == null);
        },

        isIdentified() {
            return this.user.waiting_at !== null && this.user.identified_at !== null;
        },

        isWaiting() {
            return this.user.waiting_at !== null && this.user.identified_at === null;
        },

        isVerified() {
            return this.user.email_verified_at !== null;
        },

        unreadedNotifications() {
            let counter = 0

            this.notifications.content.forEach(item => {
                if (!item.readed) {
                    counter++
                }
            })

            return counter
        },

        toComplete() {
            return this.user.birthdate === null && this.user.residence === null && this.user.city === null ? true : false
        }
    },

    created: function () {
        if (this.$cookies.isKey('auth_token')) {

            this.auth.token = this.$cookies.get('auth_token');

        }
        if (!this.$cookies.isKey('is_first_login')) {
            setTimeout(() => {
                this.firstLogin = true
            }, 1500)
        }
    },

    methods: {

        loadUser() {
            this.$http({
                method: 'post',
                url: 'api/auth/me',
                headers: {
                    'Authorization': this.auth.type + ' ' + this.auth.token
                }
            })
                .then(response => {
                    this.user = response.data;
                    this.inbox = response.data.inbox
                    // rg4js('setUser', {
                    //     identifier: this.user.id,
                    //     isAnonymous: false,
                    //     email: this.user.email,
                    //     fullName: this.user.full_name,
                    // });
                })
                .catch(error => {

                    if (error.response.status == 401) {
                        this.$cookies.remove('auth_token');
                        this.auth.token = false;
                        this.user = {};
                        this.profile.thurnail = null;
                        this.chat = {
                            display: false,
                            loading: true,
                            content: []
                        }

                        this.$router.push({name: 'Login'});
                    }

                })
        },

        loadThurnail() {

            this.$http.get('api/image/thurnail', {
                params: {
                    token: this.auth.token,
                    id: this.user.id
                }
            })
                .then(response => {

                    this.profile.thurnail = response.data.encoded;

                })
                .catch(error => {

                    if (error.response.status == 404) {

                        this.profile.thurnail = false;

                    } else {

                        setTimeout(() => {
                            this.loadThurnail();
                        }, 2000)

                    }

                })
        },

        loadTransactions() {
            this.$http.get('api/transaction/count', {
                params: {
                    token: this.auth.token
                }
            })
                .then(response => {

                    this.transactions = response.data;

                })
                .catch(() => {
                    setTimeout(() => {
                        this.loadTransactions();
                    }, 1000)
                })
        },

        getMessages(date) {

            this.$http.get('api/message/index', {
                params: {
                    token: this.auth.token,
                    start: date
                }
            })
                .then(res => {
                    res.data.forEach(item => {
                        this.chat.content.unshift(item)
                    });

                    this.chat.loading = false
                    this.inbox = 0
                    this.$socket.emit('user reading', {
                        id: this.user.id,
                        content: res.data
                    })
                    if (res.data.length < 10) {
                        this.chat.more = false
                    }
                })

        },

        getNotifications() {

            this.$http.get('api/notification/index', {
                params: {
                    token: this.auth.token,
                }
            })
                .then(res => {

                    this.notifications.content = res.data.data,
                        this.notifications.next = res.data.next

                })

        },

        async getNewNotification(id) {

            await this.$http.get('api/notification/' + id, {
                params: {
                    token: this.auth.token,
                }
            })
                .then(res => {
                    this.notifications.content.unshift(res.data)
                })

        },

        sendMessage(text) {

            let index = this.chat.content.push({
                id: null,
                content: text.replace(/\r?\n/g, '<br/>'),
                readed: 'send',
                created_at: new Date()
            })

            index--

            this.$http('api/message', {
                method: 'post',
                headers: {
                    'Authorization': this.auth.type + ' ' + this.auth.token
                },
                data: {
                    message: text.replace(/\r?\n/g, '<br/>')
                }
            })
                .then(res => {
                    this.chat.content[index].id = res.data
                    this.chat.content[index].readed = false

                    this.$socket.emit('user message', {id: this.user.id})
                })
                .catch(error => {
                    console.log(error.response)
                })

        },

        async getUnreaded() {

            await this.$http.get('api/message/unreaded', {
                params: {
                    token: this.auth.token
                }
            })
                .then(res => {
                    res.data.forEach(item => {
                        this.chat.content.push(item);

                        this.$socket.emit('user reading', {
                            id: this.user.id,
                            content: res.data
                        })
                    })

                    this.inbox = 0;
                })

        },

        markNotification(id) {

            this.$http('api/notification/mark/' + id, {
                method: 'post',
                headers: {
                    'Authorization': this.auth.type + ' ' + this.auth.token
                },
            })
                .then(res => {
                    this.notifications.content.forEach(item => {
                        if (item.id == res.data.id) {
                            item.readed = true;
                        }
                    })
                })

        },

        logout() {

            let load = this.$buefy.loading.open();

            this.$http('api/auth/logout', {
                headers: {
                    'Authorization': this.auth.type + ' ' + this.auth.token,
                },

                method: 'post'
            })
                .then(() => {

                    this.$cookies.remove('auth_token');
                    this.auth.token = false;
                    this.user = {};
                    this.profile.thurnail = null;
                    this.chat = {
                        display: false,
                        loading: true,
                        content: []
                    }

                    this.$router.push({name: 'Login'});

                    load.close();

                })

        },

        connectSocket() {

            this.$socket.auth = {
                type: 'user',
                id: this.user.id
            }
            this.$socket.connect();

            this.$socket.on('users', users => {
                this.adminsConnected = users.admins
            })

            this.$socket.on('admin message', () => {

                if (this.chat.display == false) {

                    this.inbox++

                } else {

                    this.getUnreaded();

                    let sound = new Audio(require('./assets/notification.mp3'))

                    sound.addEventListener('canplaythrough', () => {
                        sound.play()
                    })

                }

            })

            this.$socket.on('admin reading', messages => {
                messages.content.forEach(item => {
                    this.chat.content.forEach(element => {
                        if (item.id == element.id) {
                            element.readed = true
                        }
                    })
                })
            })

            this.$socket.on('admin notification', notify => {
                this.getNewNotification(notify.id)

                let sound = new Audio(require('./assets/notification.mp3'))

                sound.addEventListener('canplaythrough', () => {
                    sound.play()
                })
            })

        }

    }

}).$mount('#app')
