<template>
    <section
        v-if="count"
        aria-label="b-message headerless variants examples">
        <b-message
            type="is-warning"
        >
            <template #default>
                <b>
                    Ud tiene {{ count }} transacciones en transito:
                </b>
                <ul>
                    <li
                        v-for="transaction in transactions"
                        :key="transaction.id">
                        <div
                            v-for="(account) in transaction.accounts"
                            :key="'account-' + account.id"
                        >
                            {{account.beneficiary}} ({{account.code}}): {{account.pivot.amount}} €
                        </div>
                    </li>
                </ul>
            </template>
        </b-message>
    </section>
</template>
<script>
export default {
    name: 'PendingTransactionsAlert',
    data() {
        return {
            transactions: [],
            loading: false,
        }
    },
    mounted() {
        this.getPendingTransactionsCount();
    },
    methods: {
        getPendingTransactionsCount() {
            this.loading = true;
            this.$http('api/transaction/pending', {
                method: 'get',
                params: {
                    token: this.$root.auth.token
                }
            }).then(({data}) => {
                this.transactions = data.transactions;
            }).finally(() => {
                this.loading = false;
            });
        }
    },
    computed: {
        count() {
            return this.transactions.length;
        }
    }
}
</script>