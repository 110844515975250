<template>
    <div class="is-relative mb-6">
        <div class="container px-3">
            <div class="columns mt-4">
                <div class="column px-4">
                    <h1 class="title is-4 has-text-primary-dark">
                        Vamos a verificar tu identidad!
                    </h1>

                    <p class="px-5">
                        Vamos a proceder a validar tu identidad, debes tener a la mano tu identificación:
                    </p>

                    <ul class="px-6" style="list-style: circle;">
                        <li class="is-bold">DNI, NIE o Pasaporte</li>
                    </ul>

                    <div v-if="userDeclinedReasons !== null" class="columns is-multiline px-4 py-6">
                        <div class="column is-12">
                            <div
                                class="is-flex is-flex-direction-column is-justify-content-center mx-auto"
                                style="max-width: 364px;"
                            >
                                <h2 class="subtitle is-size-6 has-text-primary-dark has-text-centered">
                                    Razones por las que tu verificación fue rechazada:
                                </h2>

                                <ul
                                    class="is-size-7 has-text-danger-dark is-capitalized"
                                    style="list-style: square;"
                                >
                                    <li
                                        v-for="(text, index) in userDeclinedReasons" :key="`reason-${index}`"
                                        class="mb-4"
                                    >
                                        {{ text }}
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="column is-12 pt-6">
                            <p class="has-text-grey-dark is-italic has-text-centered mb-5">
                                Actualiza tus datos de ser necesario y vuelve a intentar la verificación.
                            </p>

                            <form class="mx-auto" style="max-width: 364px;">
                                <BField label="Nombres:" label-position="on-border">
                                    <BInput v-model="updateData.name" rounded placeholder="Justo como aparece en tu documento..." />
                                </BField>

                                <BField label="Apellidos:" label-position="on-border">
                                    <BInput v-model="updateData.lastname" rounded placeholder="Justo como aparece en tu documento..." />
                                </BField>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="has-text-centered"
            >
                <AmlbotKyc
                    :has-modified="dataHasModified"
                    :name="updateData.name"
                    :lastname="updateData.lastname"
                />
            </div>
        </div>
        <b-modal
            v-model="showDni"
            scroll="clip"
            :width="720">
            <figure class="image">
                <img alt="Documento de identidad" :src="preview">
            </figure>
        </b-modal>
    </div>
</template>
<script>
import AmlbotKyc from "@/views/Identify/AmlbotKyc.vue";

export default {
    data() {
        return {
            declinedReasons: [],

            dataHasModified: false,

            updateData: {
                name: this.$root.user.name,
                lastname: this.$root.user.lastname
            },

            step: 0,
            frond: null,
            back: null,
            url: {
                'frond': null,
                'back': null
            },
            preview: null,
            showDni: false,

            upload: {
                loading: false,
                progress: 0
            }
        }
    },

    watch: {
        updateData: {
            handler() {
                this.dataHasModified = true
            },
            deep: true
        }
    },

    mounted() {
        this.getDeclinedReasons()
    },

    methods: {
        send() {
            this.upload.loading = true
            let Form = new FormData

            Form.append('identification-frond', this.frond)

            if (this.identityType === 'DNI' || this.identityType === 'NIE') {
                Form.append('identification-back', this.back)
            }

            this.$http('api/validation/save', {
                headers: {
                    'Authorization': this.$root.auth.type + ' ' + this.$root.auth.token,
                    'Content-Type': 'multipart/form-data'
                },
                method: 'post',
                data: Form,
                onUploadProgress: event => {
                    if (event.lengthComputable) {

                        let percentage = Math.round((event.loaded * 100) / event.total)
                        percentage = percentage > 94 ? 94 : percentage

                        this.upload.progress = percentage

                    } else {
                        this.upload.progress = 94
                    }
                }
            })
                .then(res => {
                    let _date = new Date();
                    this.$root.user.waiting_at = _date.toUTCString();

                    this.upload.progress = 100
                    this.upload.loading = false

                    this.$socket.emit('user notification', {
                        id: res.data.notification.id,
                        user: this.$root.user
                    })

                    this.$buefy.toast.open({
                        message: 'Credenciales enviadas correctamente',
                        type: 'is-success',
                        duration: 3000,
                        position: 'is-top-right'
                    });

                    this.$router.push({name: 'Dashboard'});
                })
                .catch(error => {

                    this.upload.loading = false

                    if (error.response.status === 422) {

                        let errs = Object.values(error.response.data.errors);

                        errs.forEach(item => {
                            this.$buefy.toast.open({
                                type: 'is-danger',
                                message: item[0],
                                position: 'is-top-right',
                                duration: 3000
                            })
                        })

                    } else if (error.response.status === 500) {
                        this.$buefy.toast.open({
                            type: 'is-danger',
                            message: 'Algo ha salido mal :(',
                            position: 'is-top-right',
                            duration: 3000
                        })
                    }

                })
        },

        getDeclinedReasons() {
            this.$http('api/user/declined-reasons', {
                method: 'get',
                headers: {
                    'Authorization': this.$root.auth.type + ' ' + this.$root.auth.token
                }
            })
                .then(res => {
                    this.declinedReasons = res.data
                })
                .catch(error => {
                    console.log(error)
                })
        }
    },
    beforeMount() {

        if (!this.$root.isAuthenticate && !this.$root.isIdentified) {
            this.$router.push({name: 'Login'});
        }

    },
    computed: {
        identityType() {
            return this.$root.user.document
        },
        userDeclinedReasons() {
            if (Array.isArray(this.$root.user.user_verifications) && this.$root.user.user_verifications.length > 0) {
                const last =  this.$root.user.user_verifications[this.$root.user.user_verifications.length - 1].data || {}
                const reasons = typeof last.verifications === 'object' && last.verifications !== null && !Array.isArray(last.verifications) ? last.verifications : null

                if (reasons !== null) {
                    const messages = []

                    for (const [, value] of Object.entries(reasons)) {
                        messages.push(...(value.decline_reasons || []))
                    }

                    return [...new Set(messages)].map(
                        item => (this.declinedReasons.find(
                            reason => reason.code === item
                        ) || {}).description || ''
                    )
                }
            }

            return null
        }
    },
    name: 'Identified',
    components: {
        AmlbotKyc
    }
}
</script>

<style lang="scss">

p {
    margin-bottom: 1rem !important;
}

.canva-photo {
    background-color: #353535;
}

.is-bold {
    font-weight: bold !important;
}

</style>
