<template>
    <div class="container mt-4 px-3">
        <h1 class="title is-4 has-text-primary-dark mb-3">
            Reporte personalizado
        </h1>

        <div class="columns">
            <div class="column mt-6 mb-5">
                <h2 class="subtitle has-text-primary-dark is-4">
                    Total enviado por mes
                </h2>
            </div>
        </div>

        <div class="columns mb-3">

            <div class="column is-7 order-2">

                <rate-chart
                type="bar"
                :dataChart="dataTrans"
                :options="optionsTrans"
                v-if="readyTrans"
                >
                </rate-chart>

            </div>
            <div class="column is-5 is is-flex is-justify-content-center is-flex-direction-column">
                <div class="section">
                    <p>
                        Aquí se refleja la cantidad de <b>Euros</b> enviados, en los últimos meses.
                        Coloca el puntero del ratón, o toca sobre las barras para ver el total de cada mes
                    </p>
                </div>
            </div>

        </div>

        <div class="line-divider"></div>

        <div class="columns mt-6 mb-3">
            <div class="column">
                <h2 class="subtitle has-text-primary-dark is-4">
                    Total enviado por cuenta bancaria
                </h2>
            </div>
        </div>

        <div class="columns">

            <div class="column is-7">

                <rate-chart
                type="doughnut"
                :dataChart="dataAccounts"
                :options="optionsAccounts"
                v-if="readyAccounts"
                >
                </rate-chart>

            </div>
            <div class="column is-5 is is-flex is-justify-content-center is-flex-direction-column">
                <div class="section">
                    <p>
                        Aquí se refleja la cantidad de <b>Euros</b> enviados, a cada cuenta bancaria
                        asociada a tu perfil.
                        Coloca el puntero del ratón sobre las barras para ver el total de esa cuenta
                    </p>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import RateChart from '../components/RateChart.vue'
export default {
  data () {
    return {
        
        readyTrans: false,
        readyAccounts: false,

        transactions: [],
        accounts: [],

        dataTrans:  {
            labels: [],
            datasets:[
            {
                label: "Total transferido, ultimos 5 meses",
                data: [],
                backgroundColor: "rgba(15, 129, 131, .5)",
                borderColor: "rgb(15, 129, 131)",
                borderWidth: 3
            }
            ]
        },
        optionsTrans: {
            responsive: true,
            animation: {
                delay: 200,
                duration: 800
            },
            plugins: {
                legend: {
                    position: 'top',
                },
                title: {
                    display: false,
                },
                tooltip: {
                    callbacks: {
                        label: function(context){
                            return ' ' + context.raw + ' €';
                        }
                    }
                }
            },
            scales: {
                y:{
                    ticks: {
                        /* eslint-disable */
                        callback: function(value) {
                            return value.toLocaleString('es-ES') + ' €';
                        }
                        /* eslint-enable */
                    },
                    grid: {
                        display: false
                    }
                }
            }
        },

        dataAccounts: {
            labels: [],
            datasets:[
                {
                    label: "Total transferido a cuenta",
                    data: [],
                    backgroundColor: [],
                    hoverOffset: 10,
                    borderWidth: 1,
                    borderColor: [],
                }
            ]
        },
        optionsAccounts: {
            responsive: true,
            animation: {
                duration: 800,
                delay: 200
            },
            legend: {
                display: false
            },
            
        },

    }
  },
    mounted () {

        this.$http.get('api/report/index', {
            params: {
                token: this.$root.auth.token
            }
        })
        .then(res => {

            res.data.transactions.forEach(item => {
                
                this.dataTrans.labels.push(item[0]);
                this.dataTrans.datasets[0].data.push(item[1]);

            });

            res.data.accounts.forEach(item => {

                let color = parseInt(Math.random() * 255) + ',' + parseInt(Math.random() * 255) + ',' + parseInt(Math.random() * 255);

                this.dataAccounts.labels.push(item[0]);
                this.dataAccounts.datasets[0].data.push(item[1]);
                this.dataAccounts.datasets[0].backgroundColor.push('rgba(' + color + ', 0.5)');
                this.dataAccounts.datasets[0].borderColor.push('rgb(' + color + ')');

            });

            this.readyTrans = true;
            this.readyAccounts = true;
            
        })
        .catch(error => {
            console.log(error.response);
        })

    },

    beforeMount () {
    if (! this.$root.isAuthenticate) {
        this.$router.push({name: 'Login'});
    }
    },

    name: 'Report',
    components: {
        RateChart
    }
}
</script>
