

<template>
    <div class="container px-3">
        <div class="columns mt-4">
            <div class="column">
                <h1 class="title is-4 has-text-primary-dark">
                    Eliminar mi cuenta
                </h1>
            </div>
        </div>
        <div class="columns mt-2">
            <div class="column is-8 is-offset-2"
                 >
                <div
                    class="box mb-6 is-relative"
                >
                    <div
                        v-if="canSubmit"
                    >
                        <b-field label="Motivo"
                                 label-position="on-border"
                                 class="mt-5">
                            <b-tooltip type="is-dark"
                                       position="is-top"
                                       :triggers="['focus', 'click']"
                                       :auto-close="['outside', 'escape']"
                                       style="width: 100%">
                                <template #content>
                                    <p class="mb-0">
                                        Indique el motivo de para eliminar su cuenta
                                    </p>
                                </template>

                                <b-input type="text"
                                         rounded
                                         placeholder="Motivo..."
                                         v-model="accountDeletionRequest.motivation"
                                         required
                                >
                                </b-input>
                            </b-tooltip>

                        </b-field>
                        <div
                            class="field is-flex is-justify-content-flex-end px-2"
                        >
                            <b-button
                                label="Enviar"
                                  type="is-link"
                                  size="is-small"
                                  rounded
                                  :disabled="submitted"
                                  @click="submit"
                            >
                            </b-button>
                        </div>
                    </div>
                    <AccountDeletionRequestSentMessage
                        v-else
                    />
                </div>

            </div>
        </div>
    </div>
</template>


<script>
import AccountDeletionRequestSentMessage from "./AccountDeletionRequestSentMessage.vue";

export default {
    components: {AccountDeletionRequestSentMessage: AccountDeletionRequestSentMessage},
    data() {
        return {
            accountDeletionRequest: {
                motivation: ''
            },
            submitted: false
        }
    },
    methods: {
        submit() {
            this.$http('api/user/delete', {
                headers: {
                    'Authorization': this.$root.auth.type + ' ' + this.$root.auth.token
                },
                method: 'post',
                data: this.accountDeletionRequest,
            }).then(({data}) => {
                if (data.success) {
                    this.submitted = true;
                }
            })
        },
    },
    computed: {
        canSubmit() {
            return !this.$root.user.deletion_request && !this.submitted;
        }
    }
}

</script>