<template>
  <b-modal
      v-model="showModal"
      width="480px">
    <div class="box mb-0">
      <h3 class="subtitle">
        Registrar nueva cuenta
      </h3>
      <b-message type="is-info">
        <template #header>
          <p class="mb-0">
            Importante
          </p>
        </template>
        Asegurate que los datos suministrados sean correctos o esto podrá causar retrasos
      </b-message>

      <b-field
          label="Banco"
          label-position="on-border">
        <b-select
            expanded
            v-model="newData.bank"
            rounded
            @input="changeCode">
          <option
              value="default"
              hidden>
            Selecciona un banco
          </option>

          <option
              v-for="item in banks"
              :key="'newBank-'+item.id"
              :value="item.id">
            {{ item.name }}
          </option>
        </b-select>
      </b-field>
      <b-field
          max-length="16"
          maxlength="16"
          type="tel"
          label="Código de cuenta"
          label-position="on-border"
          style="margin-bottom: 0 !important">
        <p class="control mb-0">
          <span class="button is-static is-rounded">{{ newData.prefix }}</span>
        </p>
        <b-input
            expanded
            rounded
            type="text"
            placeholder="XXXXXXXXXXXXXXXX"
            v-model="newData.code"
        >
        </b-input>
      </b-field>
      <p class="is-size-7 px-2 has-text-info">
        Completa el código de cuenta, sin espacios en blanco ni guiones
      </p>
      <b-field label-position="on-border"
               label="Beneficiario">
        <b-input rounded
                 type="text"
                 v-model="newData.beneficiary"
                 placeholder="Juan Peréz"
        >
        </b-input>
      </b-field>

      <b-field
          label="Cédula/RIF"
          label-position="on-border">
        <b-select
            v-model="newData.type_dni"
            placeholder="Tipo de documento"
            rounded
            style="max-width: 6rem">
          <option v-for="item in typesOfDni"
                  :key="`typex-${item}`"
                  :value="item">
            {{ item }}
          </option>
        </b-select>

        <b-input type="text"
                 rounded
                 placeholder="99999999"
                 v-model="newData.dni"
                 expanded
        >
        </b-input>

      </b-field>

      <b-field label="Tipo de cuenta"
               label-position="on-border">
        <b-select expanded
                  v-model="newData.type"
                  rounded>
          <option v-for="item in account_types"
                  :key="'newType-'+item.id"
                  :value="item.id">
            {{ item.name }}
          </option>
        </b-select>
      </b-field>

      <b-field class="is-flex is-justify-content-flex-end px-2">
        <b-button
            label="Agregar"
            icon-right="plus"
            type="is-success"
            rounded
            :disabled="!isNewSend"
            @click="sendNew"
        >
        </b-button>
      </b-field>
    </div>
  </b-modal>
</template>
<script>
export default {
  name: 'AddAccount',
  props: {
    accounts: {},
    account_types: {},
    banks: {},
    newModal: {},
  },
  data() {
    return {
      typesOfDni: [
        'V', 'E', 'J', 'P', 'G'
      ],
      newData: {
        code: '',
        beneficiary: '',
        dni: '',
        type_dni: null,
        type: 1,
        bank: 'default',
        prefix: '01XX'
      },
    };
  },
  methods: {
    changeCode(){
      this.banks.forEach(item => {
        if(this.newData.bank == item.id){
          this.newData.prefix = item.code
        }
      })
    },
    sendNew() {
      const loader = this.$buefy.loading.open()

      this.$http('api/bank/account/store', {
        method: 'post',
        headers: {
          'Authorization': this.$root.auth.type + ' ' + this.$root.auth.token
        },
        data: {
          'beneficiary': this.newData.beneficiary,
          'account-code': this.newData.prefix + this.newData.code,
          've-dni': this.newData.dni,
          'dni-type': this.newData.type_dni,
          'bank': this.newData.bank,
          'account-type': this.newData.type
        }
      })
          .then(res => {
            this.accounts.push(res.data.account);
            this.$emit('close');
            loader.close()
            this.$emit('accountId', res.data.account.id);
            if (Object.prototype.hasOwnProperty.call(this.$route.query, 'redirect')) {
              this.$router.push({
                path: `${this.$route.query.redirect}&cuenta=${res.data.account.id}`
              })
            }
          })
          .catch(error => {
            console.log(error);
            if (error.response.status == 422) {
              let errs = Object.values(error.response.data.errors);
              errs.forEach(item => {
                this.$buefy.toast.open({
                  type: 'is-danger',
                  message: item[0],
                  position: 'is-top-right',
                  duration: 3000
                })
              });
            } else if (error.response.status == 403) {
              this.$buefy.toast.open({
                type: 'is-danger',
                message: error.response.data.message,
                position: 'is-top-right',
                duration: 3300
              })
            }
            loader.close();
          })
    },
  },
  computed: {
    isNewSend() {
      return this.newData.code.length == 16 && this.newData.beneficiary.length > 3 && this.newData.bank != 'default' && this.newData.dni.length > 3 ? true : false
    },
    showModal: {
      get() {
        return this.newModal;
      },
      set(val) {
        if (val === false) {
          this.$emit('close');
        }
      }
    }
  }
}
</script>
