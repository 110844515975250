<template>
    <b-field class="is-relative"
             label="Número de teléfono"
             label-position="on-border"
    >
        <figure class="image is-24x24 mr-2"
                style="position: absolute; z-index: 10; left: 1.25rem; top: .5rem;"
                v-if="selectedCountry !== undefined"
        >
            <img :src="`${apiURL}/country/flag?name=${selectedCountry.flag}`" :alt="selectedCountry.flag" />
        </figure>

        <b-autocomplete
            v-model="numberCode"
            field="code"
            dropdown-position="is-down"
            open-on-focus
            style="width: 9.5rem;"
            class="number-input"
            rounded
            placeholder="+34"
            max-height="9rem"
            :data="filteredCode"
            @input="emitValue"
        >
            <template v-slot="{ option: { code, flag, name } }">
                <div class="is-flex is-align-items-center">
                    <figure class="image is-24x24 mr-2">
                        <img :src="`${apiURL}/country/flag?name=${flag}`" :alt="flag" />
                    </figure>

                    <p class="is-flex is-justify-content-space-between has-text-centered is-flex-grow-1 mb-0" style="line-height: 1rem;">
                        <span style="max-width: 3.25rem; margin-right: .25rem; display: inline-block; overflow-x: hidden; text-overflow: ellipsis;">{{ name }}</span>
                        <span style="display: inline-block;">{{ code }}</span>
                    </p>
                </div>
            </template>
        </b-autocomplete>

        <b-input
            v-model="numberPhone"
            type="tel"
            rounded
            :placeholder="exampleNumber"
            @input="emitValue"
        />
    </b-field>
</template>

<script>
import {getCountryCallingCode, getExampleNumber, parsePhoneNumberFromString} from 'libphonenumber-js'
import examples from 'libphonenumber-js/mobile/examples'

export default {
    props: {
        value: {
            type: String,
            default: null
        },
        countries: {
            type: Array,
            default: () => []
        },
        defaultCountry: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            numberCode: `+${getCountryCallingCode(this.defaultCountry)}`,
            numberPhone: this.value
        }
    },
    computed: {
        apiURL() {
            return process.env.VUE_APP_BASE_API;
        },
        /** @returns {{ id: number, flag: string, iso: import('libphonenumber-js').CountryCode, code: string, name: string }[]} */
        countriesWithCodes() {
            return this.countries.map(
                (
                    /**
                     * @type {{ id: number, flag: string, iso: import('libphonenumber-js').CountryCode, name: string }}
                     */
                    ctr
                ) => {
                    try {
                        return { ...ctr, code: `+${getCountryCallingCode(ctr.iso)}` }
                    } catch (_e) {
                        return null
                    }
                }
            )
                .filter(
                    (i) => i !== null
                )
        },
        filteredCode() {
            return this.numberCode !== null && this.numberCode !== undefined ?
                this.countriesWithCodes.filter(
                    (ctr) =>
                        RegExp(`^.*${this.numberCode.replace(/[^0-9]/g, '')}.*$`, 'i')
                        .test(ctr.code)
                ) :
                this.countriesWithCodes
        },
        exampleNumber() {
            if (this.selectedCountry !== null && this.selectedCountry !== undefined) {
                return getExampleNumber(this.selectedCountry.iso, examples).nationalNumber
            }

            return 'XXX XX XXX'
        },
        selectedCountry() {
            return this.countriesWithCodes.find(
                (ctr) => this.numberCode === ctr.code
            )
        }
    },
    methods: {
        emitValue() {
            const number = parsePhoneNumberFromString(`${this.numberCode}${this.numberPhone}`)

            if (number && number.formatInternational()) {
                this.$emit('input', number.formatInternational())
            }
        }
    },
    name: "NumberInput"
}
</script>

<style lang="scss">
.number-input {
    input.input.is-rounded {
        border-top-left-radius: 9999px !important;
        border-bottom-left-radius: 9999px !important;
        padding-left: 3.25rem;
    }

    .dropdown-item {
        padding-right: 1.5rem !important;
    }
}
</style>
