<template>
    <div class="container px-3">

        <div class="columns mt-4">
            <div class="column">
                <h1 class="title is-4 has-text-primary-dark">
                    Cuentas de banco
                </h1>
            </div>
        </div>

        <div class="columns" 
        v-if="!isNew && isReady">
            <div class="column px-5">

                <b-field label="Buscar y filtrar" 
                label-position="on-border"
                style="max-width: 360px; margin-left: auto; margin-bottom: 0 !important"
                >   

                    <b-input
                    type="search"
                    rounded
                    Placeholder="Filtra por código o beneficiario"
                    v-model="searchModel">
                    </b-input>

                    <p class="control">
                        <b-button
                        rounded
                        icon-left="search"
                        type="is-success"
                        @click="search"
                        >
                        </b-button>
                    </p>

                </b-field>

                <div>
                    <b-button label="Agregar"
                    icon-right="plus"
                    type="is-link"
                    size="is-small"
                    rounded
                    @click="newModal = true"
                    >
                    </b-button>
                </div>

            </div>
        </div>

        <div class="columns is-flex-wrap-wrap" 
        v-if="isReady">
            
            <div class="column is-6"
            v-for="(item, index) in accounts"
            :key="'account-' + index">

                <div class="card">

                    <div class="card-header"
                    style="box-shadow: none">
                        <p class="card-header-title"></p>
                        <div class="card-header-icon">
                            <b-dropdown
                            :mobile-modal="false"
                            position="is-bottom-left"
                            >

                                <template #trigger>
                                    <b-icon
                                    icon="ellipsis-v"
                                    >
                                    </b-icon>
                                </template>

                                <b-dropdown-item
                                @click="openRedirectModal(item.id)">
                                    <b-icon
                                    icon="plus">
                                    </b-icon>
                                    Nueva transacción
                                </b-dropdown-item>
                                <b-dropdown-item 
                                @click="updateModal(index)">
                                    <b-icon
                                    icon="pen">
                                    </b-icon>
                                    Editar
                                </b-dropdown-item>
                                <b-dropdown-item 
                                @click="deleteThis(item.id)">
                                    <b-icon
                                    icon="trash">
                                    </b-icon>
                                    Eliminar
                                </b-dropdown-item>

                            </b-dropdown>
                        </div>
                    </div>

                    <div class="card-content pt-0">
                        <div class="columns is-mobile">

                            <div class="column is-9">
                                <h2 class="subtitle is-5 has-text-primary">
                                    {{ item.bank.name }}
                                </h2>
                            </div>

                            <div class="column is-3 is-flex is-flex-direction-column is-align-items-center">
                                <b-tag
                                type="is-warning"
                                size="is-large"
                                rounded>
                                    <b>{{ item.transactions_count }}</b>
                                </b-tag>
                                <div :style="{
                                    'max-width': '115px',
                                    'width': '100%'
                                }">
                                    <p class="has-text-centered is-size-7 text-desborder">Transacciones</p>
                                </div>
                            </div>

                        </div>

                        <div class="columns is-mobile">
                            
                            <div class="column pt-0 is-8">
                                <ul>
                                    <li class="mb-1">
                                        <b>{{ toCodeString(item.code) }}</b>
                                    </li>
                                    <li>
                                        {{ item.beneficiary }}
                                    </li>
                                    <li>
                                        <b>{{ item.dni_type }}</b> {{ item.dni }}
                                    </li>
                                    <li>
                                        {{ item.type.name }}
                                    </li>
                                </ul>
                            </div>

                            <div class="column is-flex is-flex-direction-column is-justify-content-flex-end is-4">
                                <h3 class="title is-4 has-text-centered has-text-primary mb-0">
                                    <total-account
                                    :identificator="item.id">
                                    </total-account>
                                    €
                                </h3>
                                <div :style="{
                                'width': '100%',
                                'max-width': '200px'
                                }">
                                    <p class="has-text-centered is-size-7 text-desborder">Total transferido</p>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

            <div class="column"
            v-if="isNew">

                <h2 class="subtitle is-4 mt-5 pt-3 mb-0 has-text-centered">
                No hay <b>cuentas bancarias</b> para mostrar
                </h2>

                <h2 class="has-text-centered">
                    <b-icon
                    icon="frown-open"
                    class="has-text-warning-dark"
                    size="is-large"
                    >
                    </b-icon>
                </h2>

                <b-field class="is-flex is-justify-content-center mt-5">
                    <b-button icon-right="plus"
                    type="is-link"
                    rounded
                    size="is-small"
                    @click="newModal = true">
                        Añadir nueva
                    </b-button>
                </b-field>

            </div>

        </div>

        <div class="columns is-flex-wrap-wrap" v-else>

            <div class="column is-6">
                <div class="box">

                    <b-skeleton animated
                    width="30%"
                    height="1.5rem"
                    >
                    </b-skeleton>

                    <div class="mt-5">
                        <b-skeleton
                        animated
                        width="100%"
                        height="1rem"
                        :count="6"
                        >
                        </b-skeleton>
                    </div>

                </div>
            </div>
            <div class="column is-6">
                <div class="box">

                    <b-skeleton animated
                    width="30%"
                    height="1.5rem"
                    >
                    </b-skeleton>

                    <div class="mt-5">
                        <b-skeleton
                        animated
                        width="100%"
                        height="1rem"
                        :count="6"
                        >
                        </b-skeleton>
                    </div>

                </div>
            </div>
            <div class="column is-6">
                <div class="box">

                    <b-skeleton animated
                    width="30%"
                    height="1.5rem"
                    >
                    </b-skeleton>

                    <div class="mt-5">
                        <b-skeleton
                        animated
                        width="100%"
                        height="1rem"
                        :count="6"
                        >
                        </b-skeleton>
                    </div>

                </div>
            </div>
            <div class="column is-6">
                <div class="box">

                    <b-skeleton animated
                    width="30%"
                    height="1.5rem"
                    >
                    </b-skeleton>

                    <div class="mt-5">
                        <b-skeleton
                        animated
                        width="100%"
                        height="1rem"
                        :count="6"
                        >
                        </b-skeleton>
                    </div>

                </div>
            </div>

        </div>

        <b-modal
            has-modal-card
            scroll="keep"
            v-model="activeUpdate">
            <form>
                <div class="modal-card"
                style="max-width: 500px">
                    <div class="modal-card-head">
                        <p class="modal-card-title mb-0">Editar cuenta bancaria</p>
                    </div>
                    <div class="modal-card-body">
                        <b-field
                        label="Beneficiario"
                        label-position="on-border">
                            
                            <b-input rounded
                            type="text"
                            v-model="updateData.beneficiary.val"
                            :disabled="updateData.beneficiary.disable"
                            icon-right="pen"
                            icon-right-clickable
                            @icon-right-click="updateData.beneficiary.disable = false"
                            >
                            </b-input>

                        </b-field>

                        <b-field
                        label="Código de cuenta"
                        label-position="on-border">

                            <b-input rounded
                            type="text"
                            v-model="updateData.account_code.val"
                            :disabled="updateData.account_code.disable"
                            icon-right="pen"
                            icon-right-clickable
                            @icon-right-click="updateData.account_code.disable = false"
                            >
                            </b-input>

                        </b-field>

                        <b-field
                        label="Cédula de identidad/RIF"
                        label-position="on-border">

                            <b-select v-model="updateData.type_dni.val"
                            placeholder="Tipo de documento"
                            rounded
                            style="max-width: 6rem"
                            v-show="! updateData.ve_dni.disable">
                                <option v-for="item in typesOfDni"
                                :key="`typex-${item}`"
                                :value="item">
                                    {{ item }}
                                </option>
                            </b-select>

                            <b-input rounded
                            type="text"
                            expanded
                            v-model="updateData.ve_dni.val"
                            :disabled="updateData.ve_dni.disable"
                            icon-right="pen"
                            icon-right-clickable
                            @icon-right-click="updateData.ve_dni.disable = false"
                            >
                            </b-input>

                        </b-field>

                        <b-field label="Banco"
                        label-position="on-border"
                        v-if="rcsLoaded">
                            <b-select expanded
                            rounded
                            v-model="updateData.bank.val"
                            >
                                
                                <option v-for="item in banks"
                                :key="'bank-'+item.id"
                                :value="item.id"
                                :selected="updateData.bank.val == item.id ? true : false">
                                    {{ item.name }}
                                </option>

                            </b-select>
                        </b-field>

                        <b-field label="Tipo de cuenta"
                        label-position="on-border"
                        v-if="rcsLoaded">
                            <b-select expanded
                            rounded
                            v-model="updateData.account_type.val"
                            >

                                <option v-for="item in account_types"
                                :key="'type-'+item.id"
                                :value="item.id"
                                :selected="updateData.account_type.val == item.id ? true : false">
                                    {{ item.name }}
                                </option>

                            </b-select>
                        </b-field>
                    </div>

                    <div class="modal-card-foot">
                        <b-button type="is-success"
                        rounded
                        icon-right="check"
                        class="ml-auto"
                        @click="sendUpdate">
                            Actualizar
                        </b-button>
                    </div>
                </div>
            </form>
        </b-modal>

      <AddAccount
          @close="newModal = false"
          :accounts.sync="accounts"
          :account_types="account_types" :banks="banks"
                  :new-modal="newModal" :types-of-dni="typesOfDni"/>

        <b-modal v-model="redirectModal"
        scroll="clip"
        :width="400">
            <div class="box mb-0">
                <b-field
                label="Monto">
                    <b-numberinput rounded
                    controls-rounded
                    controls-alignment="right"
                    min="0"
                    step="0.10"
                    v-model="redirectAmount"
                    >
                    </b-numberinput>
                </b-field>
                <p class="mb-0 has-text-right px-3">
                    <b-button icon-right="arrow-right"
                    type="is-success"
                    rounded
                    label="Ir"
                    @click="toNewTransaction"
                    >
                    </b-button>
                </p>
            </div>
        </b-modal>

    </div>
</template>
<script>
import TotalAccount from '../components/TotalAccount.vue'
import AddAccount from "@/views/Accounts/AddAccount";

export default {
  watch: {
    newModal(){
    this.$parent.$parent.navbarZ()
    },
    activeUpdate(){
    this.$parent.$parent.navbarZ()
    },
    redirectModal(){
    this.$parent.$parent.navbarZ()
    }
  },
  methods: {
    search () {

        this.isReady = false;

        this.$http.get('api/bank/account/search', {
            params: {
                token: this.$root.auth.token,
                name: this.searchModel
            }
        })
        .then(res => {

            this.accounts = res.data;
            this.isReady = true;

        })

    },

    toNewTransaction(){
        this.$router.push({
            name: 'NewTransaction',
            query: {
                cuenta: this.redirectId,
                monto: this.redirectAmount
            }
        })
    },

    openRedirectModal(index){
        this.redirectId = index
        this.redirectModal = true
    },

    toCodeString(string){
        let vector = string.match(/.{1,4}/g);
        
        return vector.join(' '); 
    },
    updateModal(index) {
        if (!this.rcsLoaded) {
            this.$http.get('api/bank/index', {
                params: {
                    token: this.$root.token
                }
            })
            .then(res => {
                this.banks = res.data;
            })
            this.$http.get('api/account-type/index', {
                params: {
                    token: this.$root.token
                }
            })
            .then(res => {
                this.account_types = res.data;
                this.rcsLoaded = true;
            })
        }

        this.updateData.id = this.accounts[index].id;

        this.updateData.beneficiary.val = this.accounts[index].beneficiary;
        this.updateData.account_code.val = this.accounts[index].code;
        this.updateData.ve_dni.val = this.accounts[index].dni;
        this.updateData.type_dni.val = this.accounts[index].dni_type;
        this.updateData.bank.val = this.accounts[index].bank_id;
        this.updateData.account_type.val = this.accounts[index].account_type_id;

        this.updateData.beneficiary.disable = true;
        this.updateData.account_code.disable = true;
        this.updateData.ve_dni.disable = true;

        this.activeUpdate = true;

    },

    sendUpdate(){

        const loader = this.$buefy.loading.open();

        this.$http('api/bank/account/'+this.updateData.id, {
            headers: {
                'Authorization': this.$root.auth.type + ' ' + this.$root.auth.token
            },
            method: 'put',
            data: {
                'beneficiary': this.updateData.beneficiary.val,
                'account-code': this.updateData.account_code.val,
                've-dni': this.updateData.ve_dni.val,
                'dni-type': this.updateData.type_dni.val,
                'bank': this.updateData.bank.val,
                'account-type': this.updateData.account_type.val
            }
        })
        .then(res => {

            this.accounts.forEach((item, index) => {
                if(item.id == this.updateData.id) {
                    this.accounts[index] = res.data
                }
            })
            loader.close();

        })
        .catch(error => {
            
            if(error.response.status == 422){
                let errs = Object.values(error.response.data.errors);

                errs.forEach(item => {
                    this.$buefy.toast.open({
                        type: 'is-danger',
                        message: item[0],
                        position: 'is-top-right',
                        duration: 3000
                    })
                });
            }

            else if(error.response.status == 403){
                this.$buefy.toast.open({
                    type: 'is-danger',
                    message: error.response.data.message,
                    position: 'is-top-right',
                    duration: 3300
                })
            }

            loader.close();
        })

    },
    getRecourses(){
        this.$http.get('api/bank/index', {
            params: {
                token: this.$root.auth.token
            }
        })
        .then(res => {
            this.banks = res.data;
            this.rcsLoaded = true;
        })
        
        this.$http.get('api/account-type/index', {
            params: {
                token: this.$root.token
            }
        })
        .then(res => {
            this.account_types = res.data;
        })
    },

    sendDelete(){
        const loader = this.$buefy.loading.open();

        this.$http('api/bank/account/' + this.deleteData, {
            headers: {
                'Authorization': this.$root.auth.type + ' ' + this.$root.auth.token
            },
            method: 'delete',
        })
        .then(() => {
            loader.close();
            
            this.accounts.forEach((item, index) => {
                if(this.deleteData == item.id){
                    this.accounts.splice(index, 1)
                }
            })
        })
        .catch(error => {
            loader.close();

            this.$buefy.toast.open({
                type: 'is-danger',
                message: error.response.data.message,
                position: 'is-top-right',
                duration: 3300
            });
        })
    },

    deleteThis(id){
        this.deleteData = id;

        this.$buefy.snackbar.open({
            indefinite: true,
            message: '¿Desea eliminar esta cuenta bancaria? esto no se puede deshacer',
            type: 'is-warning',
            cancelText: 'Cancelar',
            actionText: 'Aceptar',
            position: 'is-top',
            onAction: () => { this.sendDelete() }
        });
    },
    changeCode(){
        this.banks.forEach(item => {
            if(this.newData.bank == item.id){
                this.newData.prefix = item.code
            } 
        })
    }
  },
  data () {
    return {
        accounts: [],
        isReady: false,
        searchModel: '',
        isNew: false,
        activeUpdate: false,
        updateData: {
            beneficiary: {
                val: '',
                disable: true
            },
            account_code: {
                val: '',
                disable: true
            },
            ve_dni: {
                val: '',
                disable: true
            },
            type_dni: {
                val: null
            },
            bank: {
                val: 0,
                disable: true
            },
            account_type: {
                val: 0,
                disable: true
            },
            id: 0,
            deleteData: 0,
        },
        banks: [],
        account_types: [],
        rcsLoaded: false,
        newModal: false,
        redirectModal: false,
        redirectAmount: 20,
        redirectId: null,
        typesOfDni: [
            'V', 'E', 'J', 'P', 'G'
        ]

    }
  },


  beforeMount() {

    if (! this.$root.isAuthenticate) {
        this.$router.push({name: 'Login'});
    }
    
  },

  mounted() {
    
    this.$http.get('api/bank/account/index',{
        params: {
            token: this.$root.auth.token
        }
    })
    .then(response => {
        this.accounts = response.data.accounts
        this.isReady = true;

        if(Object.prototype.hasOwnProperty.call(this.$route.query, 'modal')){
            this.newModal = true
        }
    })
    .catch(error => {
        if(error.response.status == 404){
            this.isNew = true;
            this.isReady = true;

            if(Object.prototype.hasOwnProperty.call(this.$route.query, 'modal')){
                this.newModal = true
            }
        }
    })

    this.getRecourses();

  },
    name: 'Accounts',

    components: {
      AddAccount,

        TotalAccount

    }
}
</script>
