<template>
    <div class="">
        <img :src="source" 
        ref="img_src"
        :style="{
          'display': 'block',
          'width': '100%',
          'height': 'auto'
        }">

        <div :style="{
            'margin-top': '.5rem',
            'display': 'flex',
            'justify-content': 'center'
        }">
            <span @click="$emit('cut', emitVal())"
            v-show="disable">
              <slot name="cutter"></slot>
            </span>
        </div>

        <canvas
        ref="ctx"
        v-show="false"
        >
        </canvas>
    </div>
</template>

<script>
import Croppr from 'croppr'

export default {
  methods: {
      
      end(val){
          
          /* eslint-disable*/
          let canva = this.$refs.ctx
          let ctx = canva.getContext('2d');

          let img = new Image();
          img.src = this.source;

          ctx.clearRect(0, 0, canva.width, canva.height);

          canva.width = val.width;
          canva.height = val.height;

          ctx.drawImage(img, val.x, val.y, val.width, val.height, 0, 0, val.width, val.height);
          this.disable = true;
          
      },

      emitVal(){
        let canva = this.$refs.ctx
        this.crop = canva.toDataURL();

        return this.crop
      }

  },
  mounted () {
      /* eslint-disable */
      const crop = new Croppr(this.$refs.img_src, {
          aspectRatio: this.ratio,
          minSize: [this.min, this.min, 'px'],
          maxSize: [this.max, this.max, 'px'],
          startSize: [400, 400, 'px'],
          onCropEnd: this.end,
      });
      /* eslint-enable */
    
  },
  props: {
    min: { 
      type: [Number],
      default: 300,
      required: false
    },
    max: { 
      type: [Number],
      default: 640,
      required: false
    },
    ratio: { 
      type: [String,Number],
      default: 1,
      required: false
    },
    source: { 
      type: String,
      default: null,
      required: true
    },

  },
  data () {
    return {
        crop: '',
        disable: false
    }
  },

  name: 'ImgCut'
    
}
</script>

<style lang="scss">
    @import '~croppr/src/css/croppr.css';
</style>