<template>
    <div class="card" id="chat-box">

      <div class="card-header">
          <p class="card-header-title mb-0">{{ header }}</p>
          <div class="card-header-icon">
            <span @click="$emit('close')">
              <slot name="header-icon"
              >
              </slot>
            </span>
          </div>
      </div>

      <div class="card-content is-relative py-3" id="chat-boxes-content"
      ref="chating">

      <p class="mb-0 has-text-centered is-size-7"
      v-if="$root.chat.more">
        <a @click.prevent="$root.getMessages($moment($root.chat.content[0].created_at).format())">
          Cargar anteriores
        </a>
      </p>

        <p class="has-text-centered px-5 mt-6"
        v-if="$root.chat.loading == false && $root.chat.content.length == 0">
          <i class="has-text-grey-light">Envíanos un mensaje, y cualquiera de nuestros administradores te responderá</i>
        </p>

        <b-loading :is-full-page="false"
        v-model="$root.chat.loading">
        </b-loading>

        <div v-for="item in messages"
        :key="'message'+item.id"
        :class="item.admin != null ? 'message-box has-background-primary' : 'message-box has-background-primary-light'"
        :style="item.admin != null ? 'margin-right: auto' : 'margin-left: auto'">

          <p class="mb-0 is-size-7 has-text-grey-light"
          v-if="item.admin != null">
            {{ item.admin.name }}
          </p>

          <p :class="item.admin != null ? 'mb-0 has-text-light' : 'mb-0 has-text-primary-dark'"
          v-html="item.content"
          >  
          </p>

          <p class="is-size-7 has-text-right mb-0"
          v-if="item.admin_id == null">
            <b-icon
            :icon="item.readed == false || item.readed == true ? 'check' : 'clock'"
            :type="item.readed == false ? 'is-grey' : item.readed == true ? 'is-link' : 'is-warning'"
            size="is-small"
            >
            </b-icon>
          </p>

          <div class="triangule-right"
          v-if="item.admin == null"
          >
          </div>

          <div class="triangule-left"
          v-if="item.admin != null"
          >
          </div>

        </div>
        
      </div>

      <div class="chat-footer pt-2 mx-3 is-flex"
      style="border-top: 1px solid rgba(0,0,0, .1)">
        
        <b-field
        message="Mayúscula + Enter, para insertar una nueva linea"
        type="is-primary"
        class="message-create" 
        style="margin-bottom: 0 !important">
          <b-input type="textarea"
          maxlength="350"
          rows="1"
          v-model="message"
          @keydown.native.enter.prevent="sendMessage"
          >
          </b-input>

        </b-field>

        <b-field
        class="send-button-content"
        style="margin-bottom: 0 !important">
          <b-tooltip label="Enviar"
          type="is-dark"
          position="is-top">
            <b-button
            type="is-primary is-light"
            icon-left="location-arrow"
            class="send-button-chat"
            @click="sendMessage"
            >
            </b-button>
          </b-tooltip>
        </b-field>

      </div>

    </div>
</template>

<script>
export default {
  data () {
    return {
      message: '',
    }
  },
  methods: {
    clickClose(){

      this.$emit('close')

    },
    sendMessage(event){

        if(event.shiftKey === false && this.message.length > 0){

          this.$emit('send', this.message)
          this.message = ''
          this.scrollToBottom()

        } else if(event.shiftKey === true){

          this.message += "\n"

        }

    },
    scrollToBottom(){
      this.$nextTick()
      .then(() => {
        this.$refs.chating.scrollTop = (this.$refs.chating.clientHeight + 50)
      })
    }
  },
  props: {
    header: { 
      type: String,
      default: 'Mensajes',
      required: false
    },
    messages: {
      type: Array,
      default: () => { [] },
      required: false
    }
  },
  mounted(){

    if(this.$root.chat.loading){
      this.$root.getMessages(this.$moment().format());
    }
    this.scrollToBottom()

  }
}
</script>

<style lang="scss">
  .message-box{
    border-radius: 5px;
    max-width: 230px;
    min-width: 80px;
    width: max-content;
    padding: .35rem .6rem .2rem .6rem;
    margin-top: .5rem;

    position: relative;
  }
  #chat-box{
    width: 420px;
    position: fixed;
    z-index: 30;

    bottom: 0;
    right: 30px;
  }
  .send-button-chat{
    width: 50px;
    margin-left: .5rem;
  }
  #chat-boxes-content{
    max-height: 390px;
    min-height: 330px;
    overflow-y: auto;
  }
  .chat-footer{
    justify-content: space-between;
    p{
      margin-bottom: 0 !important;
    }
  }

  .message-create{
    width: 100%;
    textarea{
      resize: none;
      padding-top: .5rem;
      padding-bottom: .5rem;
    }
  }

  .triangule-right{
    width: 0;
    height: 0;
    border-left: 10px solid #eef1fc;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;

    position: absolute;
    right: -10px;
    bottom: .4rem;
  }
  .triangule-left{
    width: 0;
    height: 0;
    border-right: 10px solid #2244aa;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;

    position: absolute;
    left: -10px;
    bottom: .4rem;
  }

  @media (max-width: 450px){
    #chat-box{
      width: 90%;
      right: 5%;
    }
  }
</style>
