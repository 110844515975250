<template>
    <div class="timeline-container"
    :style="{'border-left-color': lineColor, 'margin-left': marginNull ? '.5rem' : '7.2rem'}"
    @click="$emit('showIn')">

        <div class="timeline-timestamp"
        @click="$emit('showIn')">
            <slot name="timestamp"
            >
            </slot>
        </div>

        <div class="timeline-circle"
        :style="'border-color: ' + circleColor"></div>

        <div class="timeline-content">
            <slot name="content"
            >
            </slot>
        </div>
        <div class="timeline-right">
            <slot name="right"
            >
            </slot>
        </div>
        
    </div>
</template>

<script>
export default {
  props: {
    marginNull: { 
      type: Boolean,
      default: false,
      required: false
    },
    circleColor: { 
      type: String,
      default: null,
      required: false
    },
    lineColor: { 
      type: String,
      default: null,
      required: false
    },

  },
  name: 'TimeLine'
}
</script>

<style lang="scss">
    
    .timeline-container{
        display: block;
        width: auto;
        position: relative;
        border-left: .5rem solid #1d61a0;
        margin-left: 7.2rem;
        transition: .3s ease-out;
        cursor: pointer;

        z-index: 5;

        &:hover{
            background-color: rgba(50,50,50,.05);

            .timeline-circle{
                background-color: #113557;
            }

        }
    }
    .timeline-circle{
        width: 1.5rem;
        height: 1.5rem;
        border-radius: (1.5rem / 2);
        background-color: #fff;
        border: 3px solid #113557;

        transition: .3s ease-out;

        position: absolute;
        top: 2.5rem;
        left: -1rem;

        z-index: 20;
    }
    .timeline-content{
        width: 60%;
        padding: 1rem .5rem;
        display: inline-block;
    }
    .timeline-right{
        width: 40%;
        padding: 2rem .5rem;
        display: inline-block;
        position: absolute;
    }
    .timeline-timestamp{
        position: absolute;
        width: 7.2rem;
        left: -7.7rem;
        top: 1.75rem;
    }

</style>