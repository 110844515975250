<template>
  <div class="container">

    <div class="columns mt-5 mb-4 px-5">
      <div class="column">
        <h1 class="title is-4 has-text-primary-dark">
          Recuperación de contraseña
        </h1>
      </div>
    </div>

    <div class="columns pt-6">
      <div class="column is-8 is-offset-2">
        <b-steps :has-navigation="false"
        type="is-primary"
        v-model="step"
        animated>

          <b-step-item label="Correo electrónico"
          icon="envelope"
          >
          </b-step-item>

          <b-step-item label="Código"
          icon="key"
          >
          </b-step-item>

          <b-step-item label="Contraseña"
          icon="user"
          >
          </b-step-item>
          
        </b-steps>
      </div>
    </div>

    <div class="columns px-5">

      <div class="column is-8 is-offset-2"
      v-if="step == 0">
        <p class="has-text-centered mb-5 px-5">
          Coloca tu dirección de correo electrónico en el campo y se te enviara el código de recuperación.
        </p>
        <b-field label="Correo electrónico"
        label-position="on-border"
        style="max-width: 360px; margin-left: auto; margin-right: auto">
          <b-input type="email"
          rounded
          v-model="email"
          placeholder="tu.correo@ejemplo.com"
          >
          </b-input>
        </b-field>

        <b-field style="max-width: 360px; margin-left: auto; margin-right: auto"
        class="is-flex is-justify-content-flex-end">
          <b-button label="Enviar"
          type="is-success"
          rounded
          :disabled="! /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/.test(email)"
          @click="send"
          >
          </b-button>
        </b-field>
      </div>

      <div class="column is-8 is-offset-2" 
      v-else-if="step == 1">
        <p class="has-text-centered mb-5 px-5">
          Hemos enviado un código de <b>8 dígitos</b> a tu dirección de <b>correo electrónico</b>, 
          por favor ingrese el código en el siguiente campo. ¿Tienes problemas? <a @click.prevent="send">solicita otro código</a>
        </p>
        <code-input class="mx-auto"
        :fields="8"
        :fieldWidth="50"
        :fieldHeight="50"
        @complete="check"
        >
        </code-input>
      </div>

      <div class="column is-8 is-offset-2"
      v-else-if="step == 2">
        <p class="has-text-centered mb-5 px-5">
          Ingresa tu nueva contraseña, recuerda que tiene que tener una logitud de, mínimo <b>8 caractéres</b>
        </p>
        <b-field label="Nueva contraseña"
        label-position="on-border"
        style="max-width: 360px; margin-left: auto; margin-right: auto">
          <b-input type="password"
          v-model="password"
          password-reveal
          rounded
          icon="key"
          >
          </b-input>
        </b-field>

        <b-field label="Repite la contraseña"
        style="max-width: 360px; margin-left: auto; margin-right: auto"
        label-position="on-border">
          <b-input type="password"
          v-model="repeat"
          rounded
          >
          </b-input>
        </b-field>

        <b-field style="max-width: 360px; margin-left: auto; margin-right: auto"
        class="is-flex is-justify-content-flex-end">
          <b-button label="Cambiar"
          type="is-success"
          rounded
          :disabled="! isPassword"
          @click="changePassword"
          >
          </b-button>
        </b-field>

      </div>

    </div>

    <b-loading is-full-page
    :can-cancel="false"
    v-model="loading"
    >
    </b-loading>

  </div>
</template>

<script>
import CodeInput from 'vue-verification-code-input'
export default {
  data () {
    return {
      step: 0,
      loading: false,
      email: '',
      code: '',
      token: '',
      password: '',
      repeat: ''
    }
  },
  computed: {
    isPassword(){
      return this.password === this.repeat && this.password.length > 7 ? true : false
    }
  },
  methods: {
    send(){

      this.loading = true;

      this.$http('api/auth/password/send', {
        method: 'post',
        data: {
          email: this.email
        }
      })
      .then(res => {
        this.step = 1
        this.$buefy.toast.open({
          type: 'is-info',
          message: res.data.message,
          duration: 3000,
          position: 'is-top-right'
        })

        this.loading = false
      })
      .catch(error => {
        this.loading = false
        if(error.response.status == 404){
          this.$buefy.toast.open({
            type: 'is-danger',
            message: error.response.data.message,
            duration: 3000,
            position: 'is-top-right'
          })
        }
      })

    },
    check(code){
      this.loading = true

      this.$http('api/auth/password/confirm', {
        method: 'post',
        data: {
          code,
          email: this.email
        }
      })
      .then(res => {
        this.loading = false,
        this.token = res.data.token
        this.step = 2

        this.$buefy.toast.open({
          type: 'is-success',
          message: res.data.message,
          duration: 3000,
          position: 'is-top-right'
        })
      })
      .catch(error => {
        this.loading = false
        this.$buefy.toast.open({
          type: 'is-danger',
          message: error.response.data.message,
          duration: 3000,
          position: 'is-top-right'
        })
      })
    },
    changePassword(){
      this.loading = true

      this.$http('api/auth/password/reset', {
        method: 'post',
        data: {
          token: this.token,
          email: this.email,
          password: this.password
        }
      })
      .then(res => {
        this.loading = false
        this.$buefy.toast.open({
          type: 'is-success',
          message: res.data.message,
          duration: 3000,
          position: 'is-top-right'
        })
        this.$router.push({
          name: 'Login'
        })
      })
      .catch(error => {
        this.loading = false
        this.$buefy.toast.open({
          type: 'is-danger',
          message: error.response.data.message,
          duration: 3000,
          position: 'is-top-right'
        })
      })
    }
  },
  watch: {
    loading () { 
      this.$parent.$parent.navbarZ()
    },

  },
  mounted () {

  },
  name: 'ResetPassword',
  components: {
    CodeInput
  }
}
</script>