<template>
    <div>
        <canvas ref="chart"></canvas>
    </div>
</template>

<script>
import Chart from 'chart.js/auto';
export default {
  props: {
    options: { 
      type: [Object, Array],
      default: () => {
          return {}
      },
      required: false
    },
    dataChart: { 
      type: [Array, Object],
      default: () => {
          return []
      },
      required: false
    },
    type: { 
      type: String,
      default: 'line',
      required: false
    },

  },
  mounted () {
      const ctx = this.$refs.chart;
      new Chart(ctx, {
          type: this.type,
          data: this.dataChart,
          options: this.options 
      })
  },
    name: 'RateChart'
}
</script>

<style lang="scss">

</style>