<template>
    <span>
      {{ total }}
    </span>
</template>

<script>
export default {
  data () {
    return {
      total: null
    }
  },
  mounted () {

    this.$http.get('api/bank/account/total=' + this.identificator, {
      params: {
        token: this.$root.auth.token
      }
    })
    .then(response => {
      this.total = response.data.total;
    })

  },
  props: {
    identificator: { 
      type: [String,Number],
      default: null,
      required: true
    },

  },
    name: 'TotalAccount'
}
</script>