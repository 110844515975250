<template>

    <div class="is-relative">
        <div class="container px-3">

            <div class="columns mt-4">

                <div class="column is-12">

                    <!-- Loaded -->
                    <h1 class="title is-4 has-text-primary-dark"
                        v-if="isLoaded">
                        Hola!, {{ $root.user.name }}
                    </h1>
                    <!-- Loading... --->
                    <b-skeleton
                        size="is-large"
                        width="40%"
                        animated
                        :active="!isLoaded">
                    </b-skeleton>
                </div>

            </div>

            <div class="columns mt-4 is-justify-content-center is-flex-wrap-wrap"
                 id="notification-important"
            >
                <div
                    class="column is-10 px-3"
                    v-if="! $root.isVerified && isLoaded"
                >
                    <div>
                        <b-message
                            type="is-warning"
                            has-icon
                        >
                            <p class="is-size-5">Valida tu dirección de correo electrónico</p>

                            Para comenzar en Cambiatuseuros, es necesario que valides tu dirección de correo
                            electrónico. Para elló se te ha enviado un código a tu correo al registrarte, si tienes
                            problemas
                            con este código puedes solicitar otro en cualquier momento

                            <div class="pt-4">
                                <b-button type="is-warning"
                                          icon-right="arrow-right"
                                          rounded
                                          class="ml-auto"
                                          style="display: block"
                                          @click=" $router.push({ name: 'VerificationEmail' }) ">
                                    Validar
                                </b-button>
                            </div>
                        </b-message>

                    </div>

                </div>

                <div
                    class="column is-10 px-3"
                    v-if="$root.user.require_verification_update && isLoaded"
                >
                    <b-message
                        type="is-info"
                        has-icon
                    >
                        <p class="is-size-5">
                            ¡Necesitamos verificar tu identidad!
                        </p>

                        <span>
                            Saludos, <b class="is-capitalized">{{ $root.user.name.toLowerCase() }}</b>. 👋
                            <br><br>
                            Queremos contarte que hemos implementado una solución avanzada de verificación de identidad automática,
                            una de las más confiables en el mercado y está diseñada para proteger tanto
                            tus datos personales como tu seguridad financiera.
                            <br><br>
                            Esta vez será más rápido y totalmente automatizado. ☺
                        </span>

                        <div
                            class="pt-5 mx-auto"
                            style="max-width: 420px;"
                        >
                            <b-button
                                expanded
                                rounded
                                type="is-info"
                                tag="router-link"
                                :to="{ name: 'Identified' }" label="Ir Ahora"
                            />
                        </div>
                    </b-message>
                </div>

                <div class="column is-10 px-3"
                     v-if="! $root.isWaiting && ! $root.isIdentified && !$root.user.require_verification_update && isLoaded">

                    <div>

                        <b-message type="is-info"
                                   has-icon>
                            <p class="is-size-5">
                                ¡Necesitamos verificar tu identidad!
                            </p>

                            Para poder empezar a usar los servicios de <b>Cambiatuseuros</b>, necesitamos verificar tu
                            identidad,
                            completa una breve prueba en la que haremos uso de la cámara de tu dispositivo, para
                            continuar

                            <div class="pt-4">
                                <b-button type="is-info"
                                          icon-right="arrow-right"
                                          rounded
                                          class="ml-auto"
                                          style="display: block"
                                          @click=" $router.push({ name: 'Identified' }) ">
                                    Verificar
                                </b-button>
                            </div>
                        </b-message>

                    </div>
                </div>

                <div class="column is-6"
                     v-if="$root.user.waiting_at != null && $root.user.identified_at == null">
                    <b-image :src="require('../assets/wait-credentials.png')"
                             class="mx-auto"
                             style="max-width: 380px"
                    >
                    </b-image>
                    <p class="has-text-centered">
                        El equipo de <b>Cambiatuseuros</b> esta analizando tú identidad,
                        en cuanto terminemos te notificaremos a tu dirección de correo electrónico
                    </p>
                </div>

            </div>

            <div class="columns">

                <div class="column is-5 order-2">
                    <div class="box has-background-primary-light">

                        <h2 class="subtitle has-text-primary-dark">
                            Tasa actual
                        </h2>

                        <!-- Loaded -->
                        <div class=""
                             v-if="isReady1">

                            <h3 class="title is-4 has-text-centered">
                                <b-icon
                                    icon="euro-sign"
                                    class="mr-3 has-background-warning p-5 rounded-total">
                                </b-icon>
                                <span class="has-text-primary">{{ lastRate }} Bs</span>
                            </h3>
                        </div>

                        <!-- Loading -->
                        <div v-else>
                            <b-skeleton
                                animated
                                width="90%"
                                height="24px"
                                position="is-centered"
                            >
                            </b-skeleton>
                        </div>

                    </div>

                    <b-message
                        v-if="$root.profile.thurnail === false"
                        has-icon
                        type="is-primary"
                        icon="user-circle"
                        icon-size="is-large">
                        Aún no tienes una foto de perfil. por favor agrega una, puedes establecerla desde la sección de
                        información de tu perfil o
                        <router-link :to="{ name: 'EditAvatar' }">clickeando aquí</router-link>
                    </b-message>
                </div>

                <div class="column is-7">
                    <div class="box">

                        <h2 class="subtitle">
                            Historial de la tasa
                        </h2>

                        <rate-chart
                            type="line"
                            :data-chart="dataRate"
                            :options="optionsRate"
                            v-if="isReady1">
                        </rate-chart>

                        <b-skeleton
                            height="200px"
                            width="90%"
                            position="is-centered"
                            animated
                            v-else
                        >
                        </b-skeleton>

                    </div>
                </div>

            </div>

            <div class="line-divider" v-show="$root.isIdentified && $root.isVerified"></div>

            <div class="columns"
                 v-if="$root.isIdentified && $root.isVerified && $root.transactions">

                <div class="column is-6">
                    <div class="box">

                        <h2 class="subtitle">
                            Ultimas transacciones
                        </h2>

                        <!-- Loaded -->
                        <div v-if="isReady2">
                            <time-line
                                margin-null
                                v-for="(item, index) in transactions"
                                :key="'timeline-'+index"
                                @showIn="$router.push({ name: 'Transaction', params: { reference: item.reference } })">

                                <template #content>
                                    <div>
                                        <p class="px-3 py-3 mb-0"
                                           style="position: relative; bottom: -10px">
                                            {{ $moment(item.created_at).fromNow() }} - <b>{{ item.amount }} €</b>
                                        </p>
                                    </div>
                                </template>

                                <template #right>
                                    <div class="p-2 has-text-centered">
                                        <b-icon :icon="item.sent_at !== null ? 'check' : 'clock'"
                                                :type="item.sent_at !== null ? 'is-success' : 'is-warning'"
                                                size="is-medium">
                                        </b-icon>
                                    </div>
                                </template>

                            </time-line>
                        </div>

                        <!-- Loading -->
                        <div v-else>

                            <div class="columns is-flex-wrap-wrap px-3">

                                <div class="column is-9">
                                    <b-skeleton
                                        width="90%"
                                        animated
                                    >
                                    </b-skeleton>
                                </div>

                                <div class="column is-3">
                                    <b-skeleton
                                        width="70%"
                                        position="is-right"
                                    >
                                    </b-skeleton>
                                </div>

                                <div class="column is-9">
                                    <b-skeleton
                                        width="90%"
                                        animated
                                    >
                                    </b-skeleton>
                                </div>

                                <div class="column is-3">
                                    <b-skeleton
                                        width="70%"
                                        position="is-right"
                                    >
                                    </b-skeleton>
                                </div>

                                <div class="column is-9">
                                    <b-skeleton
                                        width="90%"
                                        animated
                                    >
                                    </b-skeleton>
                                </div>

                                <div class="column is-3">
                                    <b-skeleton
                                        width="70%"
                                        position="is-right"
                                    >
                                    </b-skeleton>
                                </div>

                                <div class="column is-9">
                                    <b-skeleton
                                        width="90%"
                                        animated
                                    >
                                    </b-skeleton>
                                </div>

                                <div class="column is-3">
                                    <b-skeleton
                                        width="70%"
                                        position="is-right"
                                    >
                                    </b-skeleton>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>

                <div class="column is-6">

                    <h2 class="subtitle has-text-primary-dark">
                        Buscar transacción
                    </h2>

                    <div>

                        <b-field label="Referencia"
                                 class="input-search-ref">

                            <b-autocomplete rounded
                                            v-model="filterRef"
                                            icon="keyboard"
                                            placeholder="Número de referencia"
                                            :data="search.data"
                                            :loading="search.loading"
                                            max-height="300px"
                                            @typing="searching"
                                            @select="select">

                                <template slot-scope="props">
                                    <div>
                                        <h4 class="is-6 subtitle has-text-primary mb-1">#{{ props.option.ref }} - <span
                                            class="has-text-dark">{{ props.option.amount }} €</span></h4>
                                        <p :class="'mb-0 '+props.option.color">
                                            {{ props.option.type }}
                                        </p>
                                        <p class="mb-0 has-text-grey">{{ $moment(props.option.created).fromNow() }}</p>
                                    </div>
                                </template>

                            </b-autocomplete>

                        </b-field>

                        <b-field label="Fecha"
                                 class="input-search-ref">
                            <b-datepicker
                                range
                                rounded
                                icon="calendar"
                                :mobile-native="false"
                                position="is-top-left"
                                placeholder="Rango de fecha"
                                v-model="filterRange"
                            >
                            </b-datepicker>
                        </b-field>

                    </div>

                </div>

            </div>

        </div>
    </div>
</template>

<script>
import RateChart from '../components/RateChart.vue'
import TimeLine from '../components/TimeLine.vue'

export default {
    methods: {
        searching() {

            this.search.loading = true;

            this.$http('api/transaction/search', {
                method: 'post',
                headers: {
                    'Authorization': this.$root.auth.type + ' ' + this.$root.auth.token
                },
                data: {
                    ref: this.filterRef,
                    range: {
                        from: this.filterRange[0] == undefined ? null : this.$moment(this.filterRange[0]).format(),
                        to: this.filterRange[1] == undefined ? null : this.$moment(this.filterRange[1]).format()
                    }
                }
            })
                .then(res => {

                    this.search.data = []

                    res.data.forEach(item => {

                        this.search.data.push({
                            ref: item.reference,
                            amount: item.amount,
                            type: item.cash == null ? 'Transferencia bancaria' : 'Retiro en efectivo',
                            color: item.sent_at != null ? 'has-text-success-dark' : item.failed ? 'has-text-danger-dark' : 'has-text-warning-dark',
                            created: item.created_at
                        })

                    })
                    this.search.loading = false

                })

        },

        select(event) {
            this.$router.push({
                name: 'Transaction',
                params: {
                    reference: event.ref
                }
            })
        }
    },
    data() {
        return {

            isReady1: false,
            isReady2: false,
            lastRate: '',

            filterRef: '',
            filterRange: [],

            dataRate: {
                labels: [],
                datasets: [
                    {
                        label: "Valor de la tasa, ultimos 7 días",
                        data: [],
                        backgroundColor: 'rgba(249, 240, 139, .6)',
                        fill: true,
                        borderColor: "rgb(255, 200, 119)",
                        borderWidth: 3,
                    }
                ]
            },

            optionsRate: {
                responsive: true,
                lineTension: 0.5,
                scales: {
                    y: {
                        ticks: {
                            padding: 5,
                            callback: function (value) {
                                return value.toLocaleString('es-ES') + ' Bs'
                            }
                        },
                        grid: {
                            display: false
                        }
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                return context.label + ': ' + context.raw.toLocaleString('es-ES')
                            }
                        }
                    }
                }
            },

            search: {
                loading: false,
                data: []
            },

            transactions: [],

        }
    },
    beforeMount() {
        if (!this.$root.isAuthenticate) {
            this.$router.push({name: 'Login'});
        }
    },

    mounted() {

        this.$http.get('api/rate/index', {

            params: {
                token: this.$root.auth.token
            },

        })
            .then(response => {

                response.data.data.forEach(element => {

                    let date = this.$moment(element.created_at).format('dddd');
                    this.dataRate.labels.unshift(date);

                    let rate = element.rate
                    this.dataRate.datasets[0].data.unshift(rate)

                });

                this.lastRate = response.data.data[0].rate.toLocaleString('es-ES');

                this.isReady1 = true;
            })

        this.$http.get('api/transaction/index', {
            params: {
                token: this.$root.auth.token
            }
        })
            .then(response => {
                let _transactions = response.data.transactions.data;

                this.transactions = _transactions;
                this.isReady2 = true;
            })

    },

    computed: {

        isLoaded() {
            return this.$root.user.name != undefined ? true : false;
        }

    },

    name: 'Dashboard',
    components: {
        RateChart,
        TimeLine,
    }
}
</script>

<style lang="scss">
.input-search-ref {
    max-width: 420px;
    margin-left: auto;
    margin-right: auto;
}
</style>
