<template>
    <div class="card mb-3">
        <div class="card-header">
            <p class="card-header-title is-size-5 mb-0">
                Eliminar mi cuenta
            </p>
        </div>
        <div class="card-content">
            <div class="text-center">
                <router-link :to="{ name: 'AccountDeletionRequestMain' }">
                    <b-button
                        type="is-danger "
                        rounded
                        label="Eliminar mi cuenta"
                    >
                    </b-button>
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'AccountDeletionButton',
    methods: {

    }
}
</script>