<template>
    <b-message

        type="is-danger">
        <template #header>
            <p class="mb-0">
                Solicitud de eliminar cuenta enviada
            </p>
        </template>
        Tu solicitud esta siendo procesada, por favor espera que nuestro equipo tecnico se comunique
        contigo para confirmar la operación.
    </b-message>
</template>
<script>
export default {
    name: 'AccountDeletionRequestSentMessage'
}
</script>