<template>
    <b-navbar fixed-top>

      <template #brand>
        
        <b-navbar-item class="tablet-none"
        @click="$parent.sidebar"
        v-if="$root.isAuthenticate">
          <b-icon
          icon="bars"
          size="is-medium"
          >
          </b-icon>
        </b-navbar-item>
        
        <b-navbar-item class="pl-mobile-0">
          <router-link :to="{name: 'Dashboard'}">
            <img class="mobile-none"
            :src="require('../assets/logo.png')"
            alt="Logo de cambiatuseuros"
            height="70"
            >
            <img :src="require('../assets/logo-min.png')"
            alt="Logo de cambiatuseuros"
            class="tablet-none"
            height="70"
            >
          </router-link>
        </b-navbar-item>
      </template>

      <template #end>
        <b-navbar-item tag="div" 
        class="mobile-none"
        v-if="! $root.isAuthenticate">

          <div class="buttons">
            <b-button rounded
            type="is-primary"
            tag="router-link"
            :to="{ path: '/ingresar' }">
              Ingresar
            </b-button>

            <b-button rounded
            type="is-primary is-light"
            tag="router-link"
            :to="{ path: '/registrarse' }">
              Regístrate
            </b-button>
          </div>

        </b-navbar-item>

        <template v-else>

          <b-navbar-item tag="div">
            
            <b-dropdown aria-role="list"
            position="is-bottom-left"
            :mobile-modal="false"
            max-height="400px"
            scrollable
            :close-on-click="false">

              <template #trigger>
                <span id="navbar-notification"
                class="is-clickable">
                  <b-icon icon="bell"
                  size="is-medium"
                  >
                  </b-icon>

                  <b-tag type="is-danger"
                  rounded 
                  class="counter-notification"
                  v-if="$root.unreadedNotifications > 0">
                    {{ $root.unreadedNotifications }}
                  </b-tag>
                </span>
              </template>

              <div style="max-width: 360px"
              :class="{ 'has-background-primary-light': !item.readed }"
              v-for="(item, index) in $root.notifications.content"
              :key="'notification'+ item.id">

                <b-dropdown-item class="pr-4" 
                @click="openNotification(item, index)">
                  
                  <p class="mb-0 has-text-centered has-text-link">
                  {{ item.title }}
                  </p>
                  <p class="mb-0 text-desborder">
                    {{ item.body }}
                  </p>
                  <p class="mb-0 is-size-7 has-text-grey has-text-centered">
                    {{ $moment(item.created_at).fromNow() }}
                  </p>
                  
                </b-dropdown-item>

                <b-dropdown-item separator
                v-if="index != ($root.notifications.content.length - 1)"
                >
                </b-dropdown-item>

              </div>

              <b-dropdown-item class="pr-4"
              v-if="$root.notifications.content.length <= 0"
              disabled>
                <p class="mb-0 has-text-centered">
                  <i class="has-text-grey">No hay notificaciones</i>
                </p>
              </b-dropdown-item>

              <div style="max-width: 360px" 
              v-if="$root.notifications.next != null">
                <b-dropdown-item class="pr-4"
                @click="$root.offsetNotifications()">
                  <p class="mb-0 is-size-7 has-text-centered">
                    <b>Ver todas</b>
                  </p>
                </b-dropdown-item>
              </div>

            </b-dropdown>

          </b-navbar-item>

          <b-navbar-item
          tag="div">

            <b-dropdown aria-role="list"
            :mobile-modal="false"
            position="is-bottom-left"
            >

              <template #trigger 
              >
                <div class="is-flex is-align-items-center is-clickable"> 
                  
                  <b-skeleton
                  animated
                  circle
                  width="2rem"
                  height="2rem"
                  v-if="$root.profile.thurnail === null"
                  >
                  </b-skeleton>

                  <avatar
                  v-else-if="$root.profile.thurnail === false"
                  :size="34"
                  :username="$root.user.name"
                  :initials="$root.user.name.charAt(0) + $root.user.lastname.charAt(0)"
                  color="#f5f5f5"
                  >
                  </avatar>
                  
                  <img :src="$root.profile.thurnail"
                  id="avatar-menu"
                  v-else>

                  <b-icon
                  icon="caret-down">
                  
                  </b-icon>
                </div>
                
              </template>

              <b-dropdown-item
              @click="$router.push({name: 'Profile'})">
                <b-icon
                icon="user">

                </b-icon>

                Mi perfil

              </b-dropdown-item>

              <b-dropdown-item
              @click="$router.push({name: 'History'})">
                <b-icon
                icon="exchange-alt">

                </b-icon>

                Mis movimientos

              </b-dropdown-item>

              <hr class="dropdown-divider" aria-role="menu-item">

              <b-dropdown-item 
              @click="$root.logout()">
                <b-icon
                icon="sign-out-alt">

                </b-icon>

                Cerrar sesión

              </b-dropdown-item>

            </b-dropdown>

          </b-navbar-item>
        </template>
      </template>
    </b-navbar>
</template>
<script>
export default {
  methods: {
    openNotification (item, index) { 
      this.$root.markNotification(item.id)

      this.$root.notifications.show = index
      this.$root.notifications.modal = true
    }
  },
  name: 'NavBar'
}
</script>
<style lang="scss">
    #avatar-menu{
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: rgb(220, 220, 255);
  }
  .counter-messages{
    position: absolute;
    top: -.5rem;
    right: -.5rem;
  }
  #navbar-notification{
    position: relative;
    padding: 0 !important;

    .counter-notification{
      position: absolute;
      top: -1rem;
      right: -.5rem;
    }
  }
  #live-chat-notification{
    position: absolute;
    top: 4.5rem;
    z-index: 35;
    width: 240px;
    left: 50%;
    margin-left: -125px;

    background: #13255e;
    border-radius: 5px;

    .triangule-top{
      width: 0;
      height: 0;
      border-bottom: 10px solid #13255e;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;

      position: absolute;
      top: -10px;
      left: 116px;
    }
  }
</style>
