<template>
  <div class="container">

    <b-modal v-model="modal"
    scroll="clip"
    :can-cancel="false"
    :width="440">

      <div class="box mb-0 is-relative">

        <h1 class="subtitle has-text-primary-dark">
          Terminemos tu registro!
        </h1>

        <div class="px-2">

          <p class="is-size-7 mb-5">
            Por favor, completa los siguientes datos para concluir tu perfil en <b>Cambiatuseuros</b>
          </p>

          <b-field label="Fecha de nacimiento"
          label-position="on-border">
            <b-datepicker v-model="update.birthdate"
            rounded
            icon="calendar"
            :max-date="$moment().subtract('years', 18)._d"
            placeholder="Seleciona una fecha"
            >
            </b-datepicker>
          </b-field>

          <p class="is-size-7 has-text-centered">
            <b>Dirección de facturación:</b>
          </p>

          <b-field label="País de residencia"
          label-position="on-border">
            <b-select v-model="update.residence" 
            rounded
            expanded
            placeholder="Seleccione un país"
            icon="globe">

                <option :value="item"
                v-for="item in ueCountries"
                :key="'country-'+item"
                >
                {{ item }}
                </option>
                
            </b-select>
          </b-field>

          <b-field label="Ciudad"
          label-position="on-border">
            <b-input type="text"
            v-model="update.city"
            placeholder="Ciudad de residencia"
            rounded
            >
            </b-input>
          </b-field>

          <b-field label="Piso"
          label-position="on-border">
            <b-input type="text"
            v-model="update.flat"
            placeholder="Ej: 24° C"
            rounded
            >
            </b-input>
          </b-field>

          <b-field label="Calle"
          label-position="on-border">
            <b-input type="text"
            v-model="update.direction"
            placeholder="Ej: Calle Puerta de Granada, 192"
            rounded
            >
            </b-input>
          </b-field>

          <b-field class="is-grouped is-grouped-right px-3">
            <b-button label="Enviar"
            type="is-success"
            rounded
            :disabled="! isSend"
            @click="send"
            >
            </b-button>
          </b-field>

        </div>

        <b-loading :is-full-page="false"
        :can-cancel="false"
        v-model="loading"
        >
        </b-loading>

      </div>

    </b-modal>

    <b-modal v-model="avatar"
    scroll="clip"
    :width="440"
    :can-cancel="false">

      <div class="box mb-0">

        <h1 class="subtitle has-text-success-dark">
          Muy bien!
        </h1>

        <p class="has-text-centered">
          Perfecto {{ $root.user.name }}, tus datos han sido completados.
        </p>

        <p class="has-text-centered">
          <b>¿Que te parece si ahora subes una foto de perfil?</b>
        </p>

        <b-field class="is-grouped is-grouped-centered">
          <b-button label="Ir"
          icon-right="user-circle"
          type="is-link"
          rounded
          @click="$router.push({ name: 'EditAvatar' })"
          >
          </b-button>
        </b-field>

      </div>

    </b-modal>

  </div>
</template>

<script>
export default {
  data () {
    return {
      modal: false,
      loading: false,
      avatar: false,

      update: {
        birthdate: null,
        residence: null,
        city: null,
        flat: null,
        direction: null
      },

      ueCountries: 
      [
        'Venezuela', 'Alemania', 'Austria', 'Bélgica', 'Bulgaria', 'Chequia', 'Chipre', 'Croacia', 'Dinamarca', 'Eslovaquia', 'Eslovenia', 'España', 'Estonia', 'Finlandia', 'Francia', 'Grecia', 'Hungría', 'Irlanda', 'Italia', 'Letonia', 'Lituania', 'Luxemburgo', 'Malta', 'Países Bajos', 'Polonia', 'Portugal', 'Rumanía', 'Suecia' 
      ]
    }
  },
  computed: {
    isSend(){
      let count = 0;
      let array = Object.values(this.update)

      array.forEach(value => {
        if(value == null || value == ''){
          count++
        }
      });

      return count === 0 ? true : false
    }
  },
  methods: {
    send(){
      this.loading = true;

      this.$http('api/user/to-complete', {
        method: 'put',
        headers: {
          'Authorization': `${this.$root.auth.type} ${this.$root.auth.token}`
        },
        data: {
          birthdate: this.$moment(this.update.birthdate).format('YYYY-MM-DD'),
          residence: this.update.residence,
          city: this.update.city,
          flat: this.update.flat,
          direction: this.update.direction
        }
      })
      .then(res => {

        this.loading = false
        this.$root.user = res.data

        this.modal = false
        this.avatar = true

      })
      .catch(error => {

        this.loading = false

        let errs = Object.values(error.response.data.errors);

        errs.forEach(item => {
          this.$buefy.toast.open({
            type: 'is-danger',
            message: item[0],
            position: 'is-top-right',
            duration: 3000
          })
        })

      })
    }
  },
  mounted(){
    this.modal = true
  },
  name: 'ToCompletePre'
}
</script>