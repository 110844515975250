<template>
    <div class="container px-3 mt-5">
        <h1 class="title is-4 has-text-primary-dark px-3">
            Verificar dirección de correo electrónico
        </h1>

        <div class="columns">
            <div class="column">
                <div class="section px-5">
                    <p>
                        Por favor, introduzca aquí el código de verificación que hemos enviado
                        a tu dirección de correo electrónico. Si tienes problemas con el código
                        <a @click="resend">solicita otro</a> 
                    </p>
                </div>
            </div>
        </div>

        <div class="columns mt-5">
            <div class="column is-8 is-offset-2">
                <code-input
                :fieldWidth="50"
                :fieldHeight="50"
                class="mx-auto mb-5"
                @complete="sendCode">

                </code-input>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        sendCode(code){

            const loader = this.$buefy.loading.open();

            this.$http.get('api/auth/verify-email', {
                params: {
                    code: code,
                    token: this.$root.auth.token
                }
            })
            .then(response => {
                if (response.status == 200){
                    loader.close();

                    this.$buefy.toast.open({
                        durarion: 4000,
                        message: response.data.message,
                        type: 'is-success',
                        position: 'is-top-right'
                    });

                    this.$root.user.email_verified_at = new Date();

                    this.$router.push({name: 'Dashboard'});
                }    
            })
            .catch(error => {
                loader.close();

                this.$buefy.toast.open({
                    durarion: 4000,
                    message: error.response.data.message,
                    type: 'is-danger',
                    position: 'is-top-right'
                });
            })

        }, 

        resend(){
            const loader = this.$buefy.loading.open();

            this.$http.get('api/auth/resend-verify-email', {
                params: {
                    token: this.$root.auth.token
                }
            })
            .then(res => {

                loader.close();

                this.$buefy.toast.open({
                    position: 'is-top-right',
                    message: res.data.message,
                    duration: 3000,
                    type: 'is-success'
                })

            })
            .catch(error => {

                loader.close();

                this.$buefy.toast.open({
                    position: 'is-top-right',
                    message: 'Ha ocurrido un error al reenviar',
                    duration: 3000,
                    type: 'is-warning'
                });

                console.log(error.response);

            })
        }
    },
    
    beforeMount () {
        if (! this.$root.isAuthenticate) {
        this.$router.push({name: 'Login'});
        }
    },
    name: 'VerificationEmail'
}
</script>