<template>
    <aside class="px-3 has-text-light">

        <div class="columns"
        style="margin-top: 16px">
        <div class="column is-flex is-align-items-center is-flex-direction-column">

            <figure class="image is-64x64 mb-1 is-clickable"
            @click="sidebar('Profile')">
            <!-- loading -->
            <b-skeleton
            circle 
            width="64px" 
            height="64px"
            animated
            v-if="$root.profile.thurnail === null">

            </b-skeleton>

            <!-- loaded -->
            <avatar
            v-else-if="$root.profile.thurnail === false"
            :size="64"
            :username="$root.user.name"
            :initials="$root.user.name.charAt(0) + $root.user.lastname.charAt(0)"
            color="#f5f5f5"
            >
            </avatar>

            <img :src="this.$root.profile.thurnail"
            class="is-rounded avatar"
            v-else
            >

            </figure>

            <!-- loaded -->
            <p class="is-size-7" 
            v-if="$root.transactions !== null">
            Transacciones&nbsp;

            <b-tag rounded
            size="is-small"
            type="is-warning"
            >
                {{ $root.transactions }}
            </b-tag>

            </p>

            <!-- Loading... -->
            <div class="mb-2 mt-1"
            v-else>
            <b-skeleton
            animated
            width="70px"
            height="16px"
            position="is-centered"
            >

            </b-skeleton>
            </div>

            <div class="sidebar-divider mb-2"></div>

        </div>
        </div>

        <div class="columns">
        <div class="column is-flex is-align-items-center is-flex-direction-column">

            <ul class="sidebar-list">

            <li @click="sidebar('Dashboard')">
                <router-link :to="{ name: 'Dashboard' }">
                <b-icon
                icon="home"></b-icon>
                Inicio
                </router-link>
            </li>

            <li @click="sidebar('NewTransaction')">
                <router-link :to="{ name: 'NewTransaction' }">
                    <b-icon icon="plus"></b-icon>
                    Nueva transacción
                </router-link>
            </li>

            <li @click="sidebar('History')">
                <router-link :to="{name: 'History'}">
                <b-icon icon="exchange-alt"></b-icon>
                Mis movimientos
                </router-link>
            </li>

            </ul>

            <div class="sidebar-divider mb-4"></div>

            <ul class="sidebar-list">

            <li @click="sidebar('Accounts')">
                <router-link :to="{name: 'Accounts'}">
                <b-icon icon="piggy-bank"></b-icon>
                Mis cuentas
                </router-link>
            </li>

            <li @click="sidebar('Report')">
                <router-link :to="{name: 'Report'}">
                <b-icon icon="chart-bar"></b-icon>
                Reporte personalizado
                </router-link>
            </li>

            </ul>

            <div class="sidebar-divider mb-4"></div>

            <ul class="sidebar-list">

            <li @click="$root.logout()">
                <a>
                <b-icon icon="sign-out-alt"></b-icon>
                Cerrar sesión
                </a>
            </li>

            </ul>             

        </div>
        </div>
        
    </aside>
</template>

<script>
export default {
  methods: {
    sidebar(route){

        this.$parent.sidebar();
        if(this.$route.name !== route){
            this.$router.push({ name: route });
        }
        
    }
  },
    name: 'SideBar'
}
</script>