<template>
  <div>
    <div class="is-flex is-justify-content-center mt-4">
      <b-button
          @click="newModal = true"
          type="is-success"
          icon-right="plus"
          label="Añadir cuenta bancaria"
          rounded
          size="is-small"
      >
      </b-button>
    </div>
    <div
        v-for="(account, index) in accounts"
        :key="'cuenta.' + index"
        class="box">
      <h3 class="subtitle has-text-primary-dark">
        Datos del beneficiario {{index + 1}}
      </h3>
      <b-field
          label="Cuenta bancaria"
          label-position="on-border">
        <b-select
            rounded
            expanded
            v-model="account.id">
          <option :value="item.id"
                  v-for="(item, index) in userAccounts"
                  :key="'u-account-'+index">
            {{ item.bank.name }} - {{ item.beneficiary }}
          </option>
        </b-select>
      </b-field>
      <b-field
          label="Monto"
          label-position="on-border">
        <b-numberinput
            rounded
            controls-rounded
            controls-alignment="right"
            min="0"
            step="0.10"
            v-model="account.amount"
        >
        </b-numberinput>
      </b-field>
      <b-message class="px-3">
        <ul>
          <li class="mb-0">{{ getAccount(account.id).beneficiary }}</li>
          <li class="mb-0">
            <b>{{ getAccount(account.id).code }}</b>
          </li>
          <li class="mb-0">{{ getAccount(account.id).dni }}</li>
        </ul>
      </b-message>
      <p class="has-text-centered has-text-danger is-size-7 mt-3"
         v-if="modalNumber < 20">
        El monto mínimo por cuenta de banco es igualmente de 20€
      </p>
      <b-field
          class="is-flex is-justify-content-flex-end">
        <b-button
            @click="$delete(accounts,index)"
            rounded
            size="is-small"
            icon-right="minus"
            class="mr-1"
            v-if="index > 0"
            label="Eliminar"
        />
        <b-button
            v-if="index + 1 == accounts.length"
            rounded
            size="is-small"
            icon-right="plus"
            type="is-success"
            label="Añadir otro beneficiario"
            :disabled="modalNumber < 20"
            @click="addAccount"
        >
        </b-button>
      </b-field>
    </div>
    <AddAccount
        @close="newModal = false"
        @account-id="(val) => accounts[accounts.length - 1].bank = val"
        :accounts.sync="userAccounts"
        :account_types="account_types"
        :banks="banks"
        :new-modal="newModal"
    ></AddAccount>
  </div>
</template>
<script>
import AddAccount from "@/views/Accounts/AddAccount";
export default {
  name: 'SeleccionarCuentaBancaria',
  components: {AddAccount},
  props: {
    amount: {},
    modalNumber: {},
    modalSelect: {},
    userAccounts: {},
    accounts: {},
  },
  data() {
    return {
      account_types: [],
      banks: [],
      newModal: false,
    }
  },
  mounted () {
    this.addAccount();
    this.getAccountTypes();
    this.getBanks();
  },
  methods: {
    getAccount(id) {
      return this.userAccounts.find((a) => a.id == id) || {};
    },
    addAccount() {
      this.accounts.push({
        amount:20,
      });
    },
    getAccountTypes () {
      this.$http.get('api/account-type/index', {
        params: {
          token: this.$root.token
        }
      })
          .then(res => {
            this.account_types = res.data;
          })
    },
    getBanks() {
      this.$http.get('api/bank/index', {
        params: {
          token: this.$root.token
        }
      })
          .then(res => {
            this.banks = res.data;
          })
    }
  }
}
</script>
